.educ_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid white;
    margin-left: 50px;
    margin-right: 50px;
    
}
.educ_h1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.educ_h1 img{
    width: 50px;
    margin-left: -100px;
}

.expand-educ{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: flex-start;
}

.educ_container h1 {
    margin-left: 100px;
    margin-right: 100px;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 150px;
    align-items: center;
    width: 100%;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    opacity: 0;
    animation: expand 2s ease forwards;
    
}

.row-hidden{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 150px;
    align-items: center;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    opacity: 0;
    animation: fadeOut 1s ease-out forwards;
}

.row img{
    width: 120px;
    height: 120px;
    /* padding-left: 10%; */
    padding-right: 100px;
}

.row-hidden img{
    width: 120px;
    height: 120px;
    /* padding-left: 10%; */
    padding-right: 100px;
}
.row p{
    width: 50%;

}
.row-hidden p{
    width: 50%;

}

.left{
    width: 15%;
    margin-left: 100px;
}

@media(width<800px){
    .educ_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid white;
        margin-left: 20px;
        margin-right: 20px;
    }

    .educ_container h1 {
        padding-left: 0px;
        margin-right: 100px;
    }

    .row{
        display: flex;
        flex-direction: column;
        height: fit-content;
        align-items: center;
        justify-content: center;
        width: 90%;
        padding: 5px;
        margin-bottom: 20px;
        overflow: hidden;
        opacity: 0;
        animation: expand 2s ease forwards;
        max-height: 500px;
        overflow-y: auto;
        border-top: 1px solid white;
        margin-left: 20px;
    }
    .row img{
        width: 100px;
        height: 100px;
        margin-left: 90px;
    }
    .row p{
        width: 80%;

    }
    .expand-educ{
        display: flex;
        flex-direction: row;
        cursor: pointer;
        width: 100%;
    }

    .left{
        width: 100%;
        justify-content: center;
        padding-right: 95px;
    }
    .row-hidden{
        display: flex;
        flex-direction: column;
        height: fit-content;
        align-items: center;
        justify-content: center;
        width: 90%;
        overflow: hidden;
        opacity: 0;
        max-height: 500px;
        overflow-y: auto;
        margin-left: 20px;
        animation: fadeOut 1s ease-out forwards;
    }
    .row-hidden p{
        width: 80%;

    }
    .row-hidden img{
        width: 100px;
        height: 100px;
        margin-left: 90px;
    }
    
}
.container{
    background-position: center;
    background: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
     opacity: 0;
     animation: blur-in-container 1.5s ease-in forwards;;
}


@keyframes blur-in-container {
    0% {
      opacity: 0;
      filter: blur(10px);
      background-color: black;
    }
    100% {
      opacity: 1;
      filter: blur(0); /* Ajusta el valor de blur según tu preferencia */
      background-color: black;
    }
  }

.ril-image-current ril__image{
    padding-bottom: 400px;
}


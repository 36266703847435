.footer{
    border-top: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 100px;
    margin-right: 100px;
    height: 100px;
    padding-bottom: 35px;
    align-items: center;
    border-top: 1px solid;
}

.moreinfo{
    display: flex;
    justify-content: space-around;
    width: 10%;
}


.moreinfo button{
    width: 50px;
    height: 50px;
    background: none;
    border-radius: 50%;
    cursor: pointer;
    

}
.moreinfo img{
    width: 30px;
    height: 30px;
}

@media(width<800px){
    .footer{
        border-top: 1px solid black;
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding-bottom: 15px;
        align-items: center;
        border-top: 1px solid;
        
    }
    .moreinfo{
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
}
.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: black;
}

.pos{
    position: absolute;
}

.code{
    top:-200px;
    left: 30%;
}

.love{
    top: -200px;
    left: 30%;
}